import "./RatePageHeader.scss";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import discont from "./30.svg";
import discont2 from "./discont2.svg";
import discont3 from "./discont3.svg";

function RatePageHeader({ setSelectedColumn }) {
	const { t } = useTranslation();
	const [isTabletScreen, setIsTabletScreen] = useState(
		window.innerWidth <= 775
	);
	const [isMobScreen, setIsMobScreen] = useState(window.innerWidth <= 441);
	const [selectedButton, setSelectedButton] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setIsTabletScreen(window.innerWidth <= 775);
			setIsMobScreen(window.innerWidth <= 441);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setSelectedColumn(selectedButton);
	}, [selectedButton, setSelectedColumn]);

	const handlePrevious = () => {
		setSelectedButton((prev) => (prev === 0 ? 2 : prev - 1));
	};

	const handleNext = () => {
		setSelectedButton((prev) => (prev === 2 ? 0 : prev + 1));
	};

	const getClassName = (index) => {
		if (isMobScreen) {
			return index === selectedButton ? "first" : "second";
		}
		return index === 0 ? "first" : index === 1 ? "second" : "third";
	};

	return (
		<>
		<div className="rate-section__content-h1">
			<span><span>Знижки</span>для нових клієнтів</span>
		</div>
		<div className="rate-section__content-upheader">
			<div className="rate-section__content-upheader__item">
			<img src={discont} alt="" />
				
				<p>{t("ratepageupheadertext1")}</p>
			</div>
			<div className="rate-section__content-upheader__item">
			<img src={discont2} alt="" />
			
				<p>{t("ratepageupheadertext2")}</p>
			</div>
			<div className="rate-section__content-upheader__item">
			<img src={discont3} alt="" />
				
				<p>{t("ratepageupheadertext3")}</p>
			</div>
		</div>
		<div className="rate-section__content-header">
			
			<div className="rate-section__content-header__text">
				
				<p>{t("ratepageheadertext")}</p>
			</div>
			<div className="rate-section__content-header__btns">
				{isTabletScreen && !isMobScreen ? (
					<>
						<button className="arrowRate" onClick={handlePrevious}>
							&larr;
						</button>
						<button className="selected-button">
							<p>{t("ratepageheaderbtnname")}</p>
							<p>{t(`ratepageheaderbtnnamerate${selectedButton + 1}`)}</p>
						</button>
						<button className="arrowRate" onClick={handleNext}>
							&rarr;
						</button>
					</>
				) : (
					<>
						<button
							className={getClassName(0)}
							onClick={() => setSelectedButton(0)}
						>
							
							<p>{t("ratepageheaderbtnnamerate1")}</p>
							<p><span>{t("ratepageheaderbtnname1")}</span>{t("ratepageheaderbtnname11")}</p>
						</button>
						<button
							className={getClassName(1)}
							onClick={() => setSelectedButton(1)}
						>
							
							<p>{t("ratepageheaderbtnnamerate2")}</p>
							<p><span>{t("ratepageheaderbtnname2")}</span>{t("ratepageheaderbtnname22")}</p>
						</button>
						<button
							className={getClassName(2)}
							onClick={() => setSelectedButton(2)}
						>
							
							<p>{t("ratepageheaderbtnnamerate3")}</p>
							<p><span>{t("ratepageheaderbtnname3")}</span>{t("ratepageheaderbtnname33")}</p>
						</button>
					</>
				)}
			</div>
		</div>
		</>
	);
}

export default RatePageHeader;
