import "./Button.scss";
import arrow from "./images/arrow-right.svg";

function ButtonComponent({ url, text }) {
    return (
        <a href={url} className="regBtn">
            <span>{text}</span>
            <img src={arrow} alt="Arrow" />
        </a>
    );
}

export default ButtonComponent;
