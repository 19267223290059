import React, { useState, useEffect } from "react";
import "./ServicesPage.scss";

import FiveSection from '../FiveSection/FiveSection';

import goldenArrow from "./images/arrow-right-gold.svg";
import blackArrow from "./images/arrow-right-black.svg";
import check from "./images/check.png";
import background from "./images/background.png";

import { useTranslation, Trans } from "react-i18next";

// Define content for each tab
const tabContent = {
	1: {
		header: "secondsectiontabscontent1header",
		header2: "secondsectiontabscontent1text11",
		header3: "secondsectiontabscontent1text12",
		texts: [
			"secondsectiontabscontent1text1",
			"secondsectiontabscontent1text2",
			"secondsectiontabscontent1text3",
		],
	},
	2: {
		header: "secondsectiontabscontent2header",
		header2: "secondsectiontabscontent2text11",
		header3: "secondsectiontabscontent2text12",
		texts: ["secondsectiontabscontent2text1", "secondsectiontabscontent2text2"],
	},
	3: {
		header: "secondsectiontabscontent3header",
		header2: "secondsectiontabscontent3text11",
		header3: "secondsectiontabscontent3text12",
		texts: ["secondsectiontabscontent3text1", "secondsectiontabscontent3text2"],
	},
	4: {
		header: "secondsectiontabscontent4header",
		header2: "secondsectiontabscontent4text11",
		header3: "secondsectiontabscontent4text12",
		texts: ["secondsectiontabscontent4text1", "secondsectiontabscontent4text2"],
	},
	5: {
		header: "secondsectiontabscontent5header",
		header2: "secondsectiontabscontent5text11",
		// header3: "secondsectiontabscontent5text12",
		texts: ["secondsectiontabscontent5text1", "secondsectiontabscontent5text2", "secondsectiontabscontent5text3"],
	},
};

function ServicesPage() {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(1);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Initial check
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Clean up event listener
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleTabClick = (tabIndex) => {
		setActiveTab(tabIndex);

		if (isMobile) {
			setTimeout(() => {
				const element = document.getElementById(`tab-content-${tabIndex}`);
				if (element) {
					const headerOffset = 108; // Adjust this value to match your header height
					const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
					const offsetPosition = elementPosition - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth'
					});
				}
			}, 100);
		}
	};

	const renderTabContent = () => {
		const content = tabContent[activeTab];
		if (!content) return null;

		return (
			<div id={`tab-content-${activeTab}`} className="services-section__content-tabs__content">
				<h4>{t(content.header)}</h4>
				{content.header2 && (
					<span>
						<Trans i18nKey={content.header2} />
					</span>
				)}
				<br /> <br />
				{content.header3 && (
					<span>
						<Trans i18nKey={content.header3} />
					</span>
				)}

				<h5>
					{t('secondsectiontabsservices')}
				</h5>

				{content.texts.map((textKey, index) => (
					<p key={index}>
						<img src={check} alt={t(textKey)} />
						<span>
							<Trans i18nKey={textKey} />
						</span>
					</p>
				))}
			</div>
		);
	};

	return (
		<section className="services-section">
			<div className="container">
				<div className="services-section__content">
					<div className="services-section__content-header">
						<div className="services-section__content-header__text">
							<h2>{t("secondsectionh2")}</h2>
							<h3>{t("secondsectionh2__p")}</h3>
							<p>
								{t("secondsectionh2__p2")}
							</p>
							<p>
								{t("secondsectionh2__p3")}
							</p>
							<p>
								{t("secondsectionh2__p4")}
							</p>
						</div>
						<div className="services-section__content-header__img">
							<img src={background} alt="" />
						</div>
					</div>
					<div className="services-section__content-tabs">
						<div className="services-section__content-tabs__items">
							{Object.keys(tabContent).map((index) => (
								<div
									key={index}
									className={`services-section__content-tabs__items-item ${
										activeTab === Number(index) ? "active" : ""
									}`}
								>
									<span className={activeTab === Number(index) ? "active" : ""}>
										{String(index).padStart(2, "0")}
									</span>
									<button
										className={activeTab === Number(index) ? "active" : ""}
										onClick={() => handleTabClick(Number(index))}
									>
										<p>{t(`secondsectiontabs${index}`)}</p>
										<img
											src={
												activeTab === Number(index) ? goldenArrow : blackArrow
											}
											alt={t(`secondsectiontabs${index}`)}
										/>
									</button>
									{/* Content for active tab in mobile view */}
									{activeTab === Number(index) && (
										<div className="services-section__content-tabs__content-mobile">
											{renderTabContent()}
										</div>
									)}
								</div>
							))}
						</div>
						<div className="services-section__content-tabs__content-desktop">
							{renderTabContent()}
						</div>
					</div>
					<FiveSection />
				</div>
			</div>
		</section>
	);
}

export default ServicesPage;
