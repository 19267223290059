import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./FiveSection.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../Button/Button";

function FiveSection() {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const navigate = useNavigate();

  // Валидация полного имени (ПІБ)
  const validateFullName = (name) => {
    const isValid = /^[a-zA-Zа-яА-ЯіїєІЇЄ\s]+$/.test(name);
    return isValid && name.trim().split(" ").length >= 2;
  };

  // Валидация email
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Общая валидация формы
  useEffect(() => {
    const isFormValid =
      fullName.trim() &&
      phoneNumber.trim() &&
      validateEmail(email) &&
      !phoneError &&
      !fullNameError;
    setIsFormValid(isFormValid);
  }, [fullName, phoneNumber, email, phoneError, fullNameError]);

  // Обработка изменений в ПІБ (полное имя)
  const handleFullNameChange = (value) => {
    setFullName(value);
    if (!validateFullName(value)) {
      setFullNameError(t("invalidFullName"));
    } else {
      setFullNameError("");
    }
  };

  // Обработка изменений в номере телефона
  const handlePhoneChange = (value) => {
    if (!value || value.length < 10) {
      setPhoneError(t("invalidPhone"));
    } else {
      setPhoneError("");
      setPhoneNumber(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting || !isFormValid) return;

    setIsSubmitting(true);

    try {
      const response = await fetch("/sendToTelegram.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName,
          phoneNumber,
          email,
          businessName,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage(t("modalinputok"));
        setFullName("");
        setPhoneNumber("");
        setEmail("");
        setBusinessName("");

        navigate("/thanks");
      } else {
        setErrorMessage(responseData.error || t("sendError"));
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage(t("modalinputerror"));
      setSuccessMessage("");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="five-section" id="contact">
      <div className="container">
        <div className="five-section__content">
          <div className="five-section__content-inner">
            <form onSubmit={handleSubmit}>
              <h4>{t("fivesectionh4")}</h4>
              <p>{t("fivesectionh4p")}</p>

              <input
                type="text"
                placeholder={t("fivesectionplaceholder1")}
                value={fullName}
                onChange={(e) => handleFullNameChange(e.target.value)}
                className={fullNameError ? "input-error" : ""}
                autoComplete="off"
                required
              />

              <PhoneInput
                country={"ua"}
                value={phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                containerClass={phoneError ? "input-error" : ""}
              />

              <input
                type="email"
                placeholder={t("fivesectionplaceholder3")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={!validateEmail(email) && email ? "input-error" : ""}
                autoComplete="off"
                required
              />

              <input
                type="text"
                placeholder={t("fivesectionplaceholder4")}
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                autoComplete="off"
              />

              <button
                type="submit"
                className="reset-button-styles"
                disabled={!isFormValid || isSubmitting}
              >
                <ButtonComponent
                  text={
                    isSubmitting
                      ? t("formSubmitting")
                      : errorMessage
                      ? errorMessage
                      : successMessage
                      ? successMessage
                      : t("fivesectioncontentbtn")
                  }
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FiveSection;
