import "./ThirdSection.scss";
import ButtonComponent from "../Button/Button";
import { useTranslation } from "react-i18next";

import newBtn from "./new.svg";
import ai from "./ai.webp";

function ThirdSection() {
	const { t } = useTranslation();

	return (
		<section className="third-section">
			<div className="container">
				<div className="third-section__content">
					<div className="third-section__content-header">
						<h2>{t("thirdsectionh2")}</h2>
						<p>{t("thirdsectionh2p")}</p>
					</div>
					<div className="third-section__content-blocks">
						<div className="third-section__content-blocks__items">
							<div className="third-section__content-blocks__items-item">
								<p>{t("thirdsectionp")}</p>
								<p>{t("thirdsectionp1")}</p>
								<p>{t("thirdsectionblockp1")}</p>
								<ButtonComponent
									url="/rate"
									text={t("thirdsectionblockbtntext")}
								/>
							</div>
							<div className="third-section__content-blocks__items-item">
								<p>{t("thirdsectionp")}</p>
								<p>{t("thirdsectionp2")}</p>
								<p>{t("thirdsectionblockp2")}</p>
								<ButtonComponent
									url="/rate"
									text={t("thirdsectionblockbtntext")}
								/>
							</div>
							<div className="third-section__content-blocks__items-item">
								<p>{t("thirdsectionp")}</p>
								<p>{t("thirdsectionp3")}</p>
								<p>{t("thirdsectionblockp3")}</p>
								<ButtonComponent
									url="/rate"
									text={t("thirdsectionblockbtntext")}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="third-section-ai__content">
					<div className="third-section-ai__content-header">
						<div className="third-section-ai__content-header-text">
							<div className="third-section-ai__content-header-text-head">
								<h2>{t("thirdsectionh2AI")}</h2>
								<p>{t("thirdsectionh2p1AI")}	<span><img src={newBtn} alt="" /></span></p>
							
							</div>
							<div className="third-section-ai__content-header-text-desc">
								<h3>{t("thirdsectionh2p2AI")}</h3>
							</div>
							<div className="third-section-ai__content-header-text-text">
								<p>{t("thirdsectionh2p3AI")}</p>
								<p>{t("thirdsectionh2p4AI")}</p>
							</div>
						</div>
						<div className="third-section-ai__content-header-img">
							<img src={ai} alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ThirdSection;
