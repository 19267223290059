import "./FourSection.scss";
import { useTranslation } from "react-i18next";

import phone from './images/ip-phone.svg'
import laptop from './images/laptop.svg'
import router from './images/router.svg'
import voip from './images/voip.svg'

function FourSection() {
	const { t } = useTranslation();

	return (
		<section className="four-section">
			<div className="container">
				<div className="four-section__content">
					<div className="four-section__content-header">
						<h2>{t("foursectionh2")}</h2>
						<p>{t("foursectionh2p")}</p>
					</div>
					<div className="four-section__content-blocks">
						<div className="four-section__content-blocks__items">
							<div className="four-section__content-blocks__items-item">
								<p><img src={phone} alt="" /></p>
								<p>{t("foursectionp1")}</p>
								<p>{t("foursectionblockp1")}</p>
								
							</div>
							<div className="four-section__content-blocks__items-item">
                            <p><img src={laptop} alt="" /></p>
								<p>{t("foursectionp2")}</p>
								<p>{t("foursectionblockp2")}</p>
								
							</div>
							<div className="four-section__content-blocks__items-item">
                            <p><img src={router} alt="" /></p>
								<p>{t("foursectionp3")}</p>
								<p>{t("foursectionblockp3")}</p>
								
							</div>
							<div className="four-section__content-blocks__items-item">
                            <p><img src={voip} alt="" /></p>
								<p>{t("foursectionp4")}</p>
								<p>{t("foursectionblockp4")}</p>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default FourSection;
